.App-header {
  background-color: #e5dbdb;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 32px;
}

.App-link {
  background-color: #bb2a1b;
  width: 50vw;
  height: 50vw;

  max-width: 400px;
  max-height: 400px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  border: none;
  transition: all 60ms ease-in-out;

  cursor: pointer;

  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.App-link:active {
  scale: 0.98;
}

.counter {
  color: #bb2a1b;
  font-size: calc(36px + 4vmin);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}



